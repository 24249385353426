var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-divider',{staticClass:"mb-5"}),_vm._m(0),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(1),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.carRentalData.pickupLocationName)+" ")])])])]),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(2),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.carRentalData.dropoffLocationName)+" ")])])])]),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(3),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.carRentalData.pickupOnAirportPreferred)+" ")])])])]),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(4),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.carRentalData.dropoffOnAirportPreferred)+" ")])])])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(5),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.getDateFormat(_vm.carRentalData.startDateTime,_vm.carRentalData.endDateTime)))])])])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 w-full"},[_c('GoogleStaticMap',{attrs:{"multiMarkMap":_vm.mapdata}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-6"},[_c('span',{staticClass:"leading-none font-medium underline"},[_c('strong',[_vm._v("Car Rental Search Criteria")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Pickup Location:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Dropoff Location:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Pickup On Airport Preferred:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Dropoff On Airport Preferred:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Dates:")])])])
}]

export { render, staticRenderFns }