<template>
  <div>
    <vs-divider class="mb-5" />
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <span class="leading-none font-medium underline">
          <strong>Accommodation Search Criteria</strong></span
        >
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Location:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ accomSearchData.locationName }} ({{ accomSearchData.radius }} {{distUnit}})</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Dates:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ getDateFormat(accomSearchData.checkinDate,accomSearchData.checkoutDate)  }}</span>
          </div>
        </div>
      </div>
     
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Board Basis:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ accomSearchData.boardBasis }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Minimum Star Rating:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ accomSearchData.minStarRating }}</span>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col">
            <span><strong> Refundable Only:</strong></span>
          </div>
          <div class="vx-col">
            <span> {{ accomSearchData.refundableOnly  }}</span>
          </div>
        </div>
      </div>
     
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full mt-2">
        <span><strong> Rooms:</strong></span>
      </div>
    </div>
    <div class="vx-row">
      <template v-for="(room, roomIndex) in accomSearchData.rooms">
        <div class="vx-col  w-full" :key="roomIndex + '0'">
          <span> <strong>Room {{ roomIndex + 1 }}:</strong>  {{ room.typePreference}} Room Type</span>
        </div>
        <template v-for="(guest, guestIndex) in room.guests">
        <div
          class="vx-col"
          :class="guest.type.toLowerCase() === 'child' ? '' : 'w-full'"
          :key="guestIndex + '02'"
        >
          <span> {{ guest.type }}</span>
        </div>
        <div
          v-if="guest.type.toLowerCase() === 'child'"
          class="vx-col"
          :key="guestIndex + '1'"
        >
          <span>Age: {{ guest.age }}</span>
        </div>
      </template>
       
      </template>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full"> 
     <GoogleStaticMap  :multiMarkMap="mapdata"></GoogleStaticMap>
      </div>
    </div>

    
  </div>
</template>

<script>
import enquiryViewerStore from "../enquiryViewerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import axios from "@/axios.js";
import GoogleStaticMap from "./GoogleStaticMap.vue"

export default {
  name: "AccommodationSearchCriteria",
  components: {
    flatPickr,
    GoogleStaticMap
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    accomSearchData: {
      type: Object,
    },
    distUnit:{
      type: String
    }
  },
  computed:{    
     mapdata(){
      return this.getMapData();
    }

  },
  methods: {
    getMapData(){
      let dataArray=[];
      let dataObject = {
        radius:0,
        latlon :{},
        placeName:""
      };
      dataObject.radius = this.distUnit.toLowerCase() === 'miles' ? Math.round(this.accomSearchData.radius * 1609.34) : Math.round(this.accomSearchData.radius * 1000);
      dataObject.latlon = {lat: this.accomSearchData.latitude, lng: this.accomSearchData.longitude};
      dataObject.placeName = this.accomSearchData.locationName;
      dataArray.push(dataObject);
      return dataArray;

    },
    getDateFormat(checkin,checkout) {
      var checkinDate = new Date(checkin);
      var checkoutDate = new Date(checkout);
      //required format 10-Sep-2021
      //for check in
      var dayin = "";
      if (checkinDate.getDate() < 10) {
        dayin = "0" + checkinDate.getDate();
      } else {
        dayin = checkinDate.getDate() + "";
      }
      var monthin = checkinDate.toLocaleString("default", { month: "short" });
      var yearin = checkinDate.getFullYear();
      var formattedDateIn = dayin + "-" + monthin + "-" + yearin;
      // for checkout
       var dayout = "";
      if (checkoutDate.getDate() < 10) {
        dayout = "0" + checkoutDate.getDate();
      } else {
        dayout = checkoutDate.getDate() + "";
      }
      var monthout = checkoutDate.toLocaleString("default", { month: "short" });
      var yearout = checkoutDate.getFullYear();
      var formattedDateOut = dayout + "-" + monthout + "-" + yearout;
      //Night Difference
      var night = checkoutDate.getDate() - checkinDate.getDate();
      var formattedDate = formattedDateIn + " - " + formattedDateOut + " (" + night + " nights)";
      return formattedDate;
    },
  },
};
</script>

<style>
</style>
