var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-divider',{staticClass:"mb-5"}),_vm._m(0),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(1),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.flightSearchData.preferredCabinClass))])])])]),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(2),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.flightSearchData.directPreferred))])])])]),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_vm._m(3),_c('div',{staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.flightSearchData.carrier))])])])])]),_vm._m(4),_vm._l((_vm.flightSearchData.legs),function(leg,legIndex){return [_c('div',{key:legIndex + '01',staticClass:"vx-row"},[_c('div',{key:legIndex + '0',staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(leg.originName)+" - "+_vm._s(leg.destinationName))])]),_c('div',{key:legIndex + '1',staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.getDateFormat(leg.departDate)))])]),_c('div',{key:legIndex + '2',staticClass:"vx-col"},[_c('span',[_vm._v(" "+_vm._s(leg.timePreference)+" Time")])])])]}),_vm._m(5),_vm._l((_vm.flightSearchData.passengers),function(pass,passengerIndex){return [_c('div',{key:passengerIndex + '02',staticClass:"vx-row"},[_c('div',{key:passengerIndex + '0',staticClass:"vx-col",class:pass.type.toLowerCase() === 'child' ? '' : 'w-full'},[_c('span',[_vm._v(" "+_vm._s(pass.type))])]),(pass.type.toLowerCase() === 'child')?_c('div',{key:passengerIndex + '1',staticClass:"vx-col"},[_c('span',[_vm._v("Age: "+_vm._s(pass.age))])]):_vm._e()])]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-6"},[_c('span',{staticClass:"leading-none font-medium underline"},[_c('strong',[_vm._v("Flight Search Criteria")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Preferred Cabin Class:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Direct Preferred:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col"},[_c('span',[_c('strong',[_vm._v(" Carrier:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 w-full mt-2"},[_c('span',[_c('strong',[_vm._v(" Legs:")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 w-full mt-2"},[_c('span',[_c('strong',[_vm._v(" Passengers:")])])])])
}]

export { render, staticRenderFns }